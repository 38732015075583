@import '../../../styles/var';

.wrap {
    position: relative;

    .head,
    .bottom {
        position: relative;
        z-index: 5;
    }

    .head {
        position: absolute;
        left: 520px;
        top: 0;
        width: 100%;
        max-width: 525px;

        @media (max-width: $md3+px) {
            left: 40%;
        }

        @media (max-width: $md4+px) {
            left: unset;
            position: relative;
        }

        .title {}

        .text {
            margin: 20px 0 65px;

            @media (max-width: $md4+px) {
                margin: 20px 0 50px;
            }
        }

        .btn {
            display: inline-flex;

            @media (max-width: $md4+px) {
                display: none;
            }
        }
    }

    .cards {
        position: relative;
        z-index: 4;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);

        @media (max-width: $md4+px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 30px;
        }

        @media (max-width: $md5+px) {
            gap: 15px;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }

    .cardsMob {
        display: none;

        @media (max-width: 600px) {
            display: block;
        }

        .navigation {
            position: relative;
            z-index: 5;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 40px;

            .nav {
                position: relative;
                cursor: pointer;
                width: 64px;
                height: 40px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                transition: all .1s;

                @media(max-width: $md6+px) {
                    width: 56px;
                    height: 35px;
                }

                &:hover {
                    opacity: 0.7;
                }

                &L {
                    background-image: url('../../../assets/img/HomePage/Maximize/arrow_left.svg');
                }

                &R {
                    background-image: url('../../../assets/img/HomePage/Maximize/arrow_right.svg');
                }
            }
        }

        .swiperWrap {
            position: relative;
            z-index: 5;
            margin-top: 25px;
        }
    }

    .card {
        border: 1px solid $green;
        padding: 20px 20px 30px;
        height: 100%;

        @media (max-width: $md4+px) {
            grid-column: unset !important;
            grid-row: unset !important;
        }

        @media (max-width: $md5+px) {
            padding: 16px;
        }

        &:nth-child(1) {
            grid-column: 1/1;
            grid-row: 1/1;
        }

        &:nth-child(2) {
            grid-column: 2/2;
            grid-row: 2/2;
        }

        &:nth-child(3) {
            grid-column: 1/1;
            grid-row: 3/3;
        }

        &:nth-child(4) {
            grid-column: 3/3;
            grid-row: 3/3;
        }

        .image {
            width: 100%;
        }

        .subtitle {
            margin: 15px 0;
            max-width: 306px;

            @media (max-width: $md6+px) {
                max-width: 260px;
            }
        }

        .desc {
            max-width: 320px;
        }
    }

    .bottom {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 365px;

        @media (max-width: $md3+px) {
            max-width: 31%;
        }

        @media (max-width: $md4+px) {
            position: relative;
            top: unset;
            transform: unset;
            max-width: 365px;
            margin-top: 50px;
        }
    }

    .stone {
        position: absolute;
        z-index: 2;

        @media (max-width: $md4+px) {
            display: none;
        }

        &_01 {
            width: 179px;
            top: 310px;
            right: 234px;

            @media (max-width: $md3+px) {
                width: 140px;
                right: 140px;
            }
        }

        &_02 {
            width: 244px;
            bottom: 620px;
            right: 63px;

            @media (max-width: $md3+px) {
                right: 20px;
                width: 170px;
                bottom: 600px;
            }
        }

        img {
            width: 100%;
        }
    }
}

:global(.aboutSwiper) {
    overflow: visible !important;
}

:global(.aboutSwiperSlide) {
    height: auto !important;
}