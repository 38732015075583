@import '../../../styles/var';

.formContainer {
    position: relative;
}

.formWrap {
    position: relative;
    z-index: 3;

    .form {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 560px;
        margin: 0 auto;

        @media(max-width: $md6+px) {
            gap: 15px;
        }

        &.formSuccess {
            pointer-events: none;

            .successPopup {
                pointer-events: visible;
                opacity: 1;
                backdrop-filter: blur(4px);

                &Img {
                    &::before {
                        transform: scale(1) !important;
                    }
                }
            }
        }

        .successPopup {
            position: absolute;
            width: 110%;
            height: 110%;
            background: rgba($color: #000, $alpha: 0.3);
            z-index: 99999999;
            display: flex;
            justify-content: center;
            align-items: center;
            left: -5%;
            top: -5%;
            pointer-events: none;
            opacity: 0;
            gap: 30px;
            flex-direction: column;
            text-align: center;

            @media(max-width: 700px) {
                background: transparent;
            }

            @media(max-width: $md6+px) {
                gap: 20px;
            }

            &Text {
                font-size: 16px !important;
                line-height: 150%;
                color: #fff;

                @media(max-width: 700px) {
                    max-width: 350px;
                }
            }

            &Img {
                position: relative;
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media(max-width: $md6+px) {
                    width: 40px;
                    height: 40px;
                }

                svg {
                    transition: all .3s;

                    @media(max-width: $md6+px) {
                        transform: scale(0.7);
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 100px;
                    border: 4px solid #fff;
                    transform: scale(1.5);
                    transition: all .3s;

                    @media(max-width: $md6+px) {
                        border-width: 3px;
                    }
                }
            }
        }

        .formGroup {
            display: grid;
            grid-template-columns: 1fr 150px;
            height: 42px;
            font-family: $text;
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 3.06px;

            @media(max-width: 600px) {
                display: block;
            }

            @media(max-width: $md6+px) {
                font-size: 16px;
                letter-spacing: 2px;
            }

            &.error {
                input {
                    border-color: $red;
                }

                .label {
                    background: $red;
                }
            }

            input {
                width: 100%;
                height: 100%;
                border: 1px solid #ABABAB;
                background: none;
                color: #ABABAB;
                padding: 0 17px;
                font-size: inherit;
                font-weight: inherit;
                letter-spacing: inherit;
                border-radius: 0;

                &:focus {
                    border-color: #fff;
                    border-right-color: #ABABAB;
                }
            }

            .label {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 100%;
                background: #ABABAB;
                color: #000;

                @media(max-width: 600px) {
                    display: none;
                }
            }
        }

        .check {
            display: grid;
            grid-template-columns: 16px 1fr;
            gap: 10px;
            color: #ABABAB;
            font-family: $text;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 2.04px;

            &Red {
                .checkText {
                    color: $red;
                }
            }

            &Box {
                position: relative;
                cursor: pointer;
                width: 16px;
                height: 16px;
                border: 1px solid #ABABAB;

                &::before {
                    content: '';
                    opacity: 0;
                    position: absolute;
                    background: #ABABAB;
                    width: 10px;
                    height: 10px;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    transition: all .1s;
                }

                &Active {
                    &::before {
                        opacity: 1;
                    }
                }
            }

            &Text {
                font-size: 14px;
                line-height: 140%;

                a {
                    cursor: pointer;
                    color: $green;
                    font-weight: 600;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .submit {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;

            @media(max-width: $md6+px) {
                margin-top: 15px;
            }

            .btn {
                position: relative;
                cursor: pointer;
                color: #000;
                font-family: $title;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background: $green;
                padding: 13px 26px;
                display: inline-flex;
                text-align: center;
                justify-content: center;
                margin-left: auto;
                transition: all .3s;

                @media(max-width: $md6+px) {
                    font-size: 16px;
                    width: 100%;
                }

                &:hover {
                    background: rgba($color: $green, $alpha: 0.8);
                }

                &:active {
                    transform: scale(0.98);
                }
            }
        }
    }
}