@import '../../../styles/var';

.wrap {
    position: relative;

    .content {
        position: relative;
        z-index: 5;

        .title {

            br {
                @media (max-width: $md5+px) {
                    display: none;
                }
            }

            b {
                color: rgba(80, 255, 89, 0.70);
            
                @media (max-width: $md5+px) {
                    color: $green;
                }
            }
        }

        .text {
            margin: 15px 0 -80px;
            text-align: right;
            line-height: 160%;

            @media (max-width: $md4+px) {
                margin: 15px 0 -60px;
            }

            @media (max-width: 850px) {
                text-align: left;
                margin: 20px 0 40px;
            }
        }

        .btn {
            position: relative;
            display: inline-flex;
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 523px;

        @media (max-width: $md3+px) {
            width: 360px;
        }

        @media (max-width: $md4+px) {
            width: 320px;
        }

        @media (max-width: 850px) {
            left: unset;
            right: 0;
            transform: translateY(-50%);
        }
            
        @media (max-width: $md5+px) {
            transform: none;
            top: 90px;
            width: 230px;
        }
            
        @media (max-width: $md6+px) {
            width: 200px;
            right: -20px;
            opacity: 0.7;
        }
        
        img {
            width: 100%;
        }
    }
}