@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .text {
        text-align: center;
        max-width: 578px;
        margin: 30px auto 65px;

        @media (max-width: $md4+px) {
            margin: 30px 0 50px;
        }

        @media (max-width: $md6+px) {
            margin: 20px 0 40px;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;

        @media (max-width: $md4+px) {
            gap: 30px;
        }

        @media (max-width: 700px) {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        @media (max-width: $md6+px) {
            gap: 30px;
        }

        .card {
            text-align: center;

            .icon {
                width: 50px;

                @media (max-width: $md4+px) {
                    width: 45px;
                }

                @media (max-width: $md6+px) {
                    width: 40px;
                }
            }

            .desc {
                margin-top: 20px;
                font-weight: 700;
                font-family: $title;

                @media (max-width: $md6+px) {
                    margin-top: 15px;
                }
            }
        }
    }
}