$title: "FuturaMd";
$text: "Futura";

$minwidth: 375px;
$mw: 1620;
$md1: $mw + 30;
$md2: 1420;
$md3: 1220;
$md4: 992;
$md5: 768;
$md6: 480;

$red: #CD0402;
$grey: #ABABAB;
$green: #50FF59;