@import '../../../styles/var';

.button {
  @media (min-width: $md3+px) {
    --button-background: #0b0707;
    --button-color: #fff;
    --button-shadow: rgba(33, 4, 104, 0.2);
    --button-shine-left: #50FF59;
    --button-shine-right: #50FF59;
    --button-glow-start: #50FF59;
    --button-glow-end: #50FF59;

    appearance: none;
    outline: none;
    border: none;

    position: relative;
    cursor: pointer;
    z-index: 1;

    padding: 0;
    margin: 0;
    border-radius: 50px;
    background: none;
    box-shadow: 0 8px 20px var(--button-shadow);

    color: var(--button-color);
    font-family: $title;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;

    .gradient {
      position: absolute;
      inset: 0;
      border-radius: inherit;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      transform: scaleY(1.02) scaleX(1.005) rotate(-0.35deg);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: scale(1.1) translateY(-70px) rotate(0deg) translateZ(0);
        padding-bottom: 100%;
        border-radius: 50%;
        background: linear-gradient(
          90deg,
          var(--button-shine-left),
          var(--button-shine-right)
        );
        animation: rotate linear 2s infinite;
      }
    }

    span {
      z-index: 1;
      position: relative;
      display: block;
      padding: 20px 60px;
      box-sizing: border-box;
      width: fit-content;
      min-width: 124px;
      border-radius: inherit;
      background-color: var(--button-background);
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);

      @media (max-width: $md4+px) {
        font-size: 16px;
        padding: 16px 35px;
      }

      @media (max-width: $md6+px) {
        font-size: 14px;
        padding: 14px 32px;
      }

      &:before {
        content: '';
        position: absolute;
        left: -16px;
        top: -16px;
        transform: translate(var(--pointer-x, 0px), var(--pointer-y, 0px))
          translateZ(0);
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: var(--button-glow, transparent);
        opacity: var(--button-glow-opacity, 0);
        transition: opacity var(--button-glow-duration, 0.5s);
        filter: blur(20px);
      }
    }

    &:hover {
      --button-glow-opacity: 1;
      --button-glow-duration: 0.25s;
    }

    &:active {
      --button-shine-left: var(--button-glow);
      --button-shine-right: var(--button-glow);
    }
  }
  @media (max-width: 1219px) {
    border: 1px solid $green;
    padding: 16px 35px;
    border-radius: 50px;
    color: #f1edee;
    text-align: center;
    font-family: $title;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    cursor: pointer;
    text-align: center;
    &:active {
        transform: scale(0.96);
    }
  }
}

@keyframes rotate {
  to {
    transform: scale(1.1) translateY(-70px) rotate(360deg) translateZ(0);
  }
}
