@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(noise-1) {
    0%,
    2%,
    4%,
    6%,
    7%,
    9% {
        opacity: 0;
        transform: translateX(0);
    }

    1% {
        opacity: .1;
    }
    
    5% {
        opacity: .5;
        transform: translateX(-7px);
    }

    6% {
        transform: translateX(0);
    }
    
    8% {
        opacity: .3;
    }
    
    9% {
        transform: translateX(2px);
    }
    
    10% {
        transform: translateX(0);
    }

    11% {
        opacity: .6;
    }

    12% {
        opacity: 1;
    }
}

@include keyframes(noise-2) {
    0%,
    2%,
    4%,
    6%,
    7%,
    9% {
        opacity: 1;
        transform: translateX(0);
    }

    1% {
        opacity: .1;
    }
    
    5% {
        opacity: .5;
        transform: translateX(-7px);
    }

    6% {
        transform: translateX(10px);
        color: $green;
    }

    7% {
        color: transparent;
    }
    
    8% {
        opacity: .3;
    }
    
    9% {
        transform: translateX(-5px);
    }
    
    10% {
        transform: translateX(0);
    }

    11% {
        opacity: .6;
    }

    12% {
        opacity: 1;
    }
}

@include keyframes(noise) {

    0%,
    3%,
    5%,
    42%,
    44%,
    100% {
        opacity: 1;
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }

    4.3% {
        opacity: 1;
        -webkit-transform: scaleY(1.7);
        transform: scaleY(1.7);
    }

    43% {
        opacity: 1;
        -webkit-transform: scaleX(1.5);
        transform: scaleX(1.5);
    }
}

@include keyframes(noise-3) {

    0%,
    3%,
    5%,
    42%,
    44%,
    100% {
        opacity: 1;
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }

    4.3% {
        opacity: 1;
        -webkit-transform: scaleY(4);
        transform: scaleY(4);
    }

    43% {
        opacity: 1;
        -webkit-transform: scaleX(10) rotate(60deg);
        transform: scaleX(10) rotate(60deg);
    }
}
