@import '../../../styles/var';

.wrap {
  position: relative;
  z-index: 6;
}

.body {
  position: relative;
  padding-top: 280px;
  padding-bottom: 100px;

  @media (max-width: $md1+px) {
    padding-top: 220px;
  }

  @media (max-width: $md3+px) {
    padding-bottom: 0;
  }

  @media (max-width: $md4+px) {
    padding-top: 180px;
    padding-bottom: 70px;
  }

  @media (max-width: $md6+px) {
    padding-top: 230px;
    padding-bottom: 55px;
  }

  .content {
    position: relative;
    z-index: 5;

    .title {
      text-align: center;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 128px;
      margin-top: 80px;

      @media (max-width: $md4+px) {
        margin-top: 60px;
        gap: 60px;
      }

      @media (max-width: 600px) {
        flex-direction: column;
        gap: 20px;
      }

      @media (max-width: $md6+px) {
        margin-top: 40px;
        gap: 20px;
      }

      .btn {
        display: inline-flex;
      }
    }
  }

  .images {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .planet {
      z-index: 2;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
      width: 1150px;

      @media (max-width: $md1+px) {
        top: 20px;
      }

      @media (max-width: $md4+px) {
        width: 900px;
        top: 40px;
      }

      @media (max-width: $md6+px) {
        width: 550px;
        top: 90px;
      }
    }

    .comet {
      z-index: 1;
      left: 0;
      top: 143px;
      width: 383px;

      @media (max-width: $md1+px) {
        top: 83px;
        width: 360px;
      }

      @media (max-width: $md3+px) {
        display: none;
      }
    }

    .bat {
      z-index: 4;
      right: -17px;
      top: 173px;
      width: 455px;

      @media (max-width: $md1+px) {
        top: 110px;
        width: 420px;
        right: 0;
      }

      @media (max-width: $md3+px) {
        right: -20px;
        width: 380px;
      }

      @media (max-width: $md4+px) {
        width: 300px;
        top: 90px;
      }

      @media (max-width: $md5+px) {
        width: 250px;
      }

      @media (max-width: $md6+px) {
        width: 196px;
        right: 0;
        top: 130px;
      }
    }

    .stone_01 {
      z-index: 3;
      top: 607px;
      left: 50px;
      width: 188px;

      @media (max-width: $md1+px) {
        top: 547px;
      }

      @media (max-width: $md3+px) {
        display: none;
      }
    }

    .stone_02 {
      z-index: 3;
      left: 100px;
      top: 396px;
      width: 84px;

      @media (max-width: $md1+px) {
        top: 316px;
      }

      @media (max-width: $md3+px) {
        top: 200px;
        left: 20px;
      }

      @media (max-width: $md4+px) {
        width: 70px;
        top: 150px;
      }

      @media (max-width: $md6+px) {
        width: 40px;
        left: 0;
        top: 110px;
      }
    }

    .stone_03 {
      z-index: 3;
      top: 627px;
      right: 62px;
      width: 112px;

      @media (max-width: $md1+px) {
        top: 567px;
      }

      @media (max-width: $md3+px) {
        top: unset;
        bottom: -100px;
        right: 0;
      }

      @media (max-width: $md4+px) {
        width: 90px;
        bottom: -80px;
      }

      @media (max-width: $md6+px) {
        right: 50px;
        bottom: -60px;
      }
    }

    .planet,
    .comet,
    .bat,
    .stone_01,
    .stone_02,
    .stone_03 {
      position: absolute;
    }

    img {
      width: 100%;
    }
  }
}