@import '../../../styles/var';

.wrap {
    position: relative;

    .image {
        position: absolute;
        top: -400px;
        right: 0;
        width: 791px;

        @media (max-width: $md3+px) {
            width: 500px;
            top: -250px;
        }

        @media (max-width: $md4+px) {
            width: 300px;
            top: -150px;
        }

        @media (max-width: $md5+px) {
            width: 220px;
            top: -100px;
        }

        @media (max-width: $md6+px) {
            width: 120px;
            top: -80px;
        }
    }
}

.body {
    position: relative;
    z-index: 5;

    .head {
        position: relative;
        z-index: 5;

        .title {
            display: flex;
            flex-direction: column;
        }

        .text {
            max-width: 525px;
            margin: 30px 0 65px;

            @media (max-width: $md4+px) {
                margin: 30px 0 50px;
            }
    
            @media (max-width: $md6+px) {
                margin: 20px 0 40px;
            }
        }
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: 65px;

        @media (max-width: $md4+px) {
            gap: 50px;
        }

        &_01,
        &_02 {
            display: grid;
            grid-template-columns: repeat(2, 480px);
            gap: 80px;

            @media (max-width: $md3+px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: $md4+px) {
                gap: 50px;
            }

            @media (max-width: $md5+px) {
                display: flex;
                flex-direction: column;
            }
        }

        &_01 {}

        &_02 {
            justify-items: flex-end;
            justify-content: flex-end;
        }

        .card {
            display: flex;
            gap: 30px;

            @media (max-width: $md4+px) {
                gap: 20px;
            }

            @media (max-width: $md6+px) {
                flex-direction: column;
                gap: 20px;
            }

            .num {
                color: $green;
                font-family: $title;
                font-size: 100px;
                font-weight: 400;
                line-height: 70%;
                letter-spacing: -3px;

                @media (max-width: $md4+px) {
                    font-size: 50px;
                }
            }

            .content {

                .subtitle {}

                .desc {
                    margin-top: 10px;
                }
            }
        }
    }
}