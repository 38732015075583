@import '../../../styles/var';

.howWorks {
    position: relative;
    z-index: 5;

    .head {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        justify-content: space-between;
        align-items: flex-end;
        gap: 50px 60px;

        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: 850px) {
            display: flex;
            flex-direction: column-reverse;
            gap: 30px;
        }

        @media (max-width: $md6+px) {
            gap: 20px;
        }

        .content {

            .text {

            }

            .subtitle {
                margin-top: 15px;
            }
        }

        .title {
            text-align: right;
            display: flex;
            flex-direction: column;

            @media(max-width: 850px) {
                text-align: left;
                width: 100%;
            }
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        justify-content: space-between;
        gap: 50px 60px;
        margin-top: 75px;

        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: $md4+px) {
            margin-top: 50px;
        }

        @media(max-width: 850px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .card {
            &Title {}

            &Line {
                margin: 20px 0;
                width: 100%;
                height: 3px;
                background: $green;

                @media(max-width: $md6+px) {
                    margin: 15px 0;
                    height: 2px;
                }
            }

            &Text {}
        }
    }
}