@import '../../../styles/var';

.marquees {
    position: relative;
    padding: 100px 0;

    @media(max-width: $md4+px) {
        padding: 50px 0;
    }

    @media(max-width: $md6+px) {
        padding: 30px 0;
    }

    .marquee {
        position: relative;
        color: #000;
        font-family: $title;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        @media(max-width: $md4+px) {
            font-size: 16px;
        }

        @media(max-width: $md6+px) {
            font-size: 14px;
        }

        p {
            padding: 20px 0 20px 9px;

            @media(max-width: $md4+px) {
                padding-left: 8px;
            }

            @media(max-width: $md6+px) {
                padding: 14px 0 14px 7px;
            }

            span {
                color: #ABABAB;
            }
        }

        &_01 {
            z-index: 3;
            background: $green;
            transform: rotate(8deg);
            left: -20px;
            width: calc(100% + 40px);
        }

        &_02 {
            z-index: 2;
            background: #ABABAB;
            transform: rotate(-10deg);
            left: -20px;
            width: calc(100% + 40px);
        }
    }
}