.noiseWrap {
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.vignette {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 0px 150px 20px black;
  mix-blend-mode: multiply;
  -webkit-animation: vignette-anim 3s infinite;
  -moz-animation: vignette-anim 3s infinite;
  -o-animation: vignette-anim 3s infinite;
  animation: vignette-anim 3s infinite;
}

.noise {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.15;
}

@-webkit-keyframes vignette-anim {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }
}

@-moz-keyframes vignette-anim {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }
}

@-o-keyframes vignette-anim {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }
}

@keyframes vignette-anim {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }
}
