@import '../../../styles/var';

.cards {
    position: relative;

    .comet {
        position: absolute;
        z-index: 2;
        top: -90px;
        left: 50%;
        transform: translateX(-50%);
        width: 359px;

        @media (max-width: $md4+px) {
            width: 300px;
        }

        @media (max-width: $md5+px) {
            left: unset;
            transform: unset;
            right: 0;
            top: -70px;
            width: 200px;
        }

        @media (max-width: $md6+px) {
            right: -20px;
            top: -50px;
            width: 130px;
        }

        img {
            width: 100%;
        }
    }

    .head {
        position: relative;
        z-index: 5;

        .title {
            text-align: right;
            display: flex;
            flex-direction: column;

            @media (max-width: 850px) {
                text-align: left;
            }
        }

        .text {
            max-width: 525px;

            @media (max-width: $md4+px) {
                max-width: 440px;
            }

            @media (max-width: 850px) {
                max-width: 100%;
                margin: 30px 0 50px;
            }

            @media (max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }

    .navigation {
        position: relative;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 40px;

        .nav {
            position: relative;
            cursor: pointer;
            width: 64px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            transition: all .1s;

            @media(max-width: $md6+px) {
                width: 56px;
                height: 35px;
            }

            &:hover {
                opacity: 0.7;
            }

            &L {
                background-image: url('../../../assets/img/HomePage/Maximize/arrow_left.svg');
            }

            &R {
                background-image: url('../../../assets/img/HomePage/Maximize/arrow_right.svg');
            }
        }
    }

    .swiperWrap {
        position: relative;
        z-index: 5;
        margin-top: 25px;

        .card {
            height: 100%;

            @media(min-width: $md6+px) {
                &:hover {
                    .imageContainer {
                        &::before {
                            opacity: 1;
                        }
                    }

                    .image {
                        transform: scale(1.1);
                    }
                }
            }

            .imageContainer {
                position: relative;
                width: 100%;

                &::before {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    width: calc(100% + 18px);
                    height: calc(100% + 18px);
                    top: -9px;
                    left: -9px;
                    background-image: url('../../../assets/img/HomePage/Maximize/border.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    opacity: 0;
                    transition: all .3s;

                    @media(max-width: $md6+px) {
                        transition: all 1s;
                    }
                }

                .imageWrap {
                    position: relative;
                    z-index: 3;
                    overflow: hidden;
                    width: 100%;

                    .image {
                        width: 100%;
                        transition: all .3s;

                        @media(max-width: $md6+px) {
                            transform: scale(1.1);
                            transition: all 1s;
                        }
                    }
                }

                &.active {
                    @media(max-width: $md6+px) {
                        .image {
                            transform: scale(1);
                        }

                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }

            .title {
                margin: 29px 0 15px;
            }

            .text {}
        }
    }
}

:global(.cardsSwiper) {
    overflow: visible !important;
}