@import '../../../styles/var';

.wrap {
    position: relative;

    .content {
        position: relative;
        z-index: 5;

        .title {

            b {

                &:nth-child(2) {
                    color: rgba(80, 255, 89, 0.70);
                }

                &:nth-child(4) {
                    color: rgba(80, 255, 89, 0.50);
                }
            }
        }

        .btn {
            display: inline-flex;
            margin-top: 50px;

            @media (max-width: $md6+px) {
                display: none;
            }
        }

        .text {
            margin: 215px 0 0 auto;
            max-width: 525px;
            width: 100%;

            @media (max-width: $md4+px) {
                margin-top: 160px;
            }

            @media (max-width: $md5+px) {
                max-width: 100%;
            }

            @media (max-width: $md6+px) {
                margin-top: 208px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        width: 802px;

        @media (max-width: $md3+px) {
            width: 600px;
        }

        @media (max-width: $md4+px) {
            width: 400px;
        }

        @media (max-width: $md6+px) {
            width: 340px;
            bottom: 47px;
        }
    }

    .stone {
        position: absolute;
        z-index: 3;

        @media (max-width: $md5+px) {
            display: none;
        }

        &_01 {
            width: 197px;
            bottom: 9px;
            left: 44px;

            @media (max-width: $md4+px) {
                width: 130px;
                left: 0;
            }
        }

        &_02 {
            width: 180px;
            top: 97px;
            right: 64px;

            @media (max-width: $md4+px) {
                width: 150px;
                top: 60px;
                right: 20px;
            }
        }

        img {
            width: 100%;
        }
    }
}