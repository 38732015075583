@import "./_var.scss";
@import './mixins';

body,
html {
    background: #000;
    color: #ABABAB;

    &.lock {
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #000;
    }

    ::-webkit-scrollbar-thumb {
        background: $green;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $green;
    }
}

.font-80 {
    color: $green;
    font-family: $title;
    font-size: 80px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 13px;

    @media(max-width: $md4+px) {
        font-size: 60px;
        letter-spacing: 10px;
    }

    @media(max-width: $md6+px) {
        font-size: 40px;
        letter-spacing: 6px;
    }
}

.font-75 {
    color: #ABABAB;
    font-family: $title;
    font-size: 75px;
    font-weight: 400;
    line-height: 115%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 35px;
    }

    span {
        display: inline-block;
        color: #CD0402;
        @include animation("noise-1 linear 1s alternate infinite");
    }
}

.font-74 {
    color: $green;
    font-family: $title;
    font-size: 74px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 50px;
    }

    @media(max-width: $md6+px) {
        font-size: 30px;
    }

    span {
        font-size: 35px;
        line-height: 115%;

        @media(max-width: $md4+px) {
            font-size: 28px;
        }

        @media(max-width: $md6+px) {
            font-size: 16px;
        }
    }
}

.font-50 {
    color: #ABABAB;
    font-family: $title;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 25px;
    }

    span {
        color: $green;
    }
}

.font-35 {
    color: $green;
    font-family: $title;
    font-size: 35px;
    font-weight: 700;
    line-height: 115%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 28px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-25 {
    color: #ABABAB;
    font-family: $title;
    font-size: 25px;
    font-weight: 400;
    line-height: 130%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-20 {
    color: #ABABAB;
    font-family: $title;
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-18 {
    color: #ABABAB;
    font-family: $text;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 3px;

    @media(max-width: $md4+px) {
        font-size: 16px;
        letter-spacing: 2.7px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 2.4px;
    }

    span {
        font-family: $title;
        color: $green;
        letter-spacing: 3px;
        font-weight: 500;
    }
}

.font-18-title {
    color: #ABABAB;
    font-family: $title;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }

    span {
        color: $green;
        display: inline-flex;
    }
}

.anim-3 {
    @include animation("noise-1 linear 1s alternate infinite");
}

.mt {
    margin-top: 250px;

    @media (max-width: $md4+px) {
        margin-top: 170px;
    }

    @media (max-width: $md6+px) {
        margin-top: 100px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.ovf-padding {
    padding-right: 5px;

    @media(max-width: $md3+px) {
        padding: 0;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}