@import '../../../styles/var';

.footerWrap {
  background: $green;
}

.footer {
  position: relative;
  z-index: 10;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  color: #000;
  font-family: $text;
  padding: 45px 0 150px;

  @media (max-width: $md3+px) {
    padding-bottom: 100px;
  }

  @media (max-width: $md4+px) {
    font-size: 16px;
  }

  @media (max-width: $md5+px) {
    padding: 25px 0 50px;
  }

  .content {
    position: relative;
    z-index: 2;
    overflow: hidden;

    .footer-word {
      position: absolute;
      right: 0;
      font-weight: 900;
      font-size: 250px;
      line-height: 0;
      z-index: 0;
      color: #d8d8d8;
    }

    .docs {
      position: relative;
      z-index: 2;
      display: flex;
      gap: 60px;
      margin-top: 20px;

      &>a {
        color: #000;
        cursor: pointer;

        &:hover {
          color: rgba($color: #000, $alpha: 0.8);
          text-decoration: underline;
        }
      }

      @media (max-width: $md4+px) {
        flex-direction: column;
        gap: 10px;
      }
    }

    .header {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;

      @media (max-width: $md5+px) {
        display: block;
      }

      .left {
        display: flex;
        align-items: flex-end;
        gap: 30px;

        @media (max-width: $md5+px) {
          justify-content: space-between;
        }

        .logo {
          margin-right: 60px;
          width: 56px;

          @media (max-width: $md6+px) {
            width: 50px;
          }

          img {
            width: 100%;
          }
        }
      }

      .right {
        display: flex;
        gap: 30px;
        align-items: center;

        @media (max-width: $md5+px) {
          margin-top: 33px;
          justify-content: flex-start;
        }

        svg {
          cursor: pointer;

          path {
            transition: all 0.1s;
          }

          &:hover {
            path {
              fill: rgba($color: #000, $alpha: 0.6);
            }
          }
        }
      }
    }

    .info {
      position: relative;
      z-index: 2;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      font-weight: 300;

      @media (max-width: $md5+px) {
        margin-top: 32px;
      }

      .address {
        @media (max-width: $md5+px) {
          margin-top: 0;
        }
      }
    }

    .under {
      position: relative;
      z-index: 2;
      margin-top: 20px;
      display: flex;
      gap: 60px;

      @media (max-width: $md4+px) {
        flex-direction: column;
        gap: 0px;
      }

      @media (max-width: $md5+px) {
        margin-top: 32px;
      }

      .address {
        @media (max-width: $md5+px) {
          margin-top: 0;
        }
      }
    }
  }
}